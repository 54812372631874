/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from "react";

import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import ClientInformation from "../components/ClientInformation/ClientInformation";
import ManualPointsButton from "../components/ManualPointsButton/ManualPointsButton";
import NavbarComponent from "../components/NavbarComponent/NavbarComponent";
import RedeemRewardButton from "../components/RedeemRewardButton/RedeemRewardButton";
import SearchBox from "../components/SearchBox/SearchBox";
import {
  AdyenStatusNotification,
  useAdyenStatusNotificationSubscription,
  useCardAcquisitionMutation,
  useGetDataFromSearchedUserLazyQuery,
} from "../graphql/__generated__/graphql_types";

import "./HomeOverview.scss";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
};

const HomeOverviewPage = () => {
  const { t } = useTranslation();
  const [userData, setUserdata] = useState<AdyenStatusNotification>();
  const [userFromSearch, setUserFromSearch] = useState(initialState);

  const searchRef = useRef<any>(null);

  // TODO: Terminal is now hardcoded. Create feature to select one.
  const { data: { adyenIncomingPayment } = {} } =
    useAdyenStatusNotificationSubscription();

  const [getDataViaCardAcquisition] = useCardAcquisitionMutation();

  const cardAcquisition = () => {
    getDataViaCardAcquisition()
      .then(({ data }) => {
        const user =
          data?.cardAcquisition.__typename === "User" &&
          data.cardAcquisition.email
            ? data.cardAcquisition
            : null;

        if (user) {
          setUserFromSearch({ ...user });
          toast.success("User found");
          return;
        }

        if (data?.cardAcquisition.__typename === "SMSPayload") {
          toast.success("Registrations sms was sent");
          setUserdata(undefined);
        }
      })
      .catch(() => {
        toast.error("Something went wrong...");
        setUserdata(undefined);
      });
  };

  const [getData] = useGetDataFromSearchedUserLazyQuery({
    onCompleted: ({ getDataFromSearchedUser }) => {
      const {
        countOfEcomPayments,
        countOfPOSPayments,
        totalCountOfTransactions,
        totalAmountOfPoints,
        user,
      } = getDataFromSearchedUser;
      setUserdata({
        countOfEcomPayments,
        countOfPOSPayments,
        totalCountOfTransactions,
        totalAmountOfPoints,
        user,
        isCardRecognized: true,
        status: "",
      });
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (adyenIncomingPayment?.status === "pending") {
      setUserdata({
        status: "pending",
        ...{},
      });
      setUserFromSearch(initialState);
      // Clear search input field when payment is coming in.
      searchRef.current.clearState();
    }
  }, [adyenIncomingPayment?.status]);

  useEffect(() => {
    // use effect to fill a userData object to pass as props, when user is recognized
    if (
      adyenIncomingPayment &&
      adyenIncomingPayment.status !== "pending" &&
      adyenIncomingPayment.user
    ) {
      const {
        countOfEcomPayments,
        countOfPOSPayments,
        totalAmountOfPoints,
        amountOfLastPayment,
        user,
        status,
        totalCountOfTransactions,
        date,
      } = adyenIncomingPayment;

      setUserdata({
        amountOfLastPayment: amountOfLastPayment!,
        countOfEcomPayments: countOfEcomPayments!,
        countOfPOSPayments: countOfPOSPayments!,
        totalAmountOfPoints: totalAmountOfPoints!,
        user,
        totalCountOfTransactions,
        status,
        date,
      });
    }
  }, [adyenIncomingPayment]);

  useEffect(() => {
    if (adyenIncomingPayment?.status !== "pending")
      setUserdata(adyenIncomingPayment);
  }, [adyenIncomingPayment]);

  useEffect(() => {
    const getUserData = async () => {
      if (userFromSearch?.email.length) {
        // TODO: Fill userData and create userData object
        getData({
          variables: {
            email: userFromSearch.email,
          },
        });
      }
    };
    getUserData();
  }, [getData, userFromSearch.email]);

  // TODO: Check if process can be more optimized. To put state level up for example
  return (
    <>
      <div id="homeOverview">
        <NavbarComponent backIcon={false} />
        <div className="cardBox mt-5 bg-white">
          <div className="cardBox-head">
            <ClientInformation memberInformation={userData} />
          </div>
          <div className="cardBox-body pb-3">
            <ul className="list-unstyled d-flex pt-4 pb-3 justify-content-center">
              <li className="herkend text-main-text">
                <span className="shape" />
                Herkend
              </li>
              <li className="terugkerend text-main-text mx-3">
                <span className="shape" />
                Terugkerend
              </li>
              <li className="nieuw text-main-text">
                <span className="shape" />
                Nieuw
              </li>
            </ul>
            <div className="cardBox-body__btn mb-2">
              <Button variant="main-bg" onClick={cardAcquisition}>
                {t("Start card acquisition", "common")}
              </Button>
            </div>
            <div className="btn-points-block">
              <ManualPointsButton
                enabled={!!adyenIncomingPayment?.user || !!userFromSearch.email}
                customerData={adyenIncomingPayment?.user || userFromSearch}
                totalAmountOfPoints={userData?.totalAmountOfPoints as number}
              />
              <RedeemRewardButton
                enabled={!!adyenIncomingPayment?.user || !!userFromSearch.email}
                customerData={adyenIncomingPayment?.user || userFromSearch}
                totalAmountOfPoints={userData?.totalAmountOfPoints as number}
              />
            </div>
            <div className="cardBox-body__search my-3">
              <SearchBox
                orderId={adyenIncomingPayment?.orderId}
                cardAlias={adyenIncomingPayment?.customerKey}
                setUserFromSearch={setUserFromSearch}
                ref={searchRef}
                isCardAlreadyConnected={!!userData?.isCardConnectedToUser}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeOverviewPage;
