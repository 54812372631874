import React from "react";

import "./Reward.scss";
import Redeemed from "../Redeemed/Redeemed";

type RewardCardProps = {
  title: string;
  discription: string;
  rewardImage: string;
  redeemed: boolean;
  level: number;
  levelName: string;
};

const RewardCard = ({
  title,
  discription,
  rewardImage,
  redeemed,
  level,
  levelName,
}: RewardCardProps) => (
  <div id="rewards-card" className="d-flex flex-column p-5 text-start m-1">
    <div
      className={`level-label ${
        level >= 1 && level <= 6 ? `level-${level}-label-bg` : false
      }`}
    >{`Level ${level}- ${levelName}`}</div>
    <div className="image-block">
      <div className="redeemed-label">{redeemed && <Redeemed />}</div>
      <img src={rewardImage} alt="Reward" />
    </div>
    <div className="content-block d-flex flex-column mt-4">
      <h5 className="fs-5 mb-2">{title}</h5>
      <p className="fs-6 fw-bold mb-2">{discription}</p>
    </div>
  </div>
);

export default RewardCard;
