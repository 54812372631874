import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import wallet from "../../../assets/add-to-wallet.png";
import level1Svg from "../../../assets/svg/level1.svg";
import level2Svg from "../../../assets/svg/level2.svg";
import level3Svg from "../../../assets/svg/level3.svg";
import level4Svg from "../../../assets/svg/level4.svg";
import level5Svg from "../../../assets/svg/level5.svg";
import level6Svg from "../../../assets/svg/level6.svg";
import { useGetUserLevelByEmailQuery } from "../../../graphql/__generated__/graphql_types";
import { useAppContext } from "../../../utils/contextLib";

import "./Header.scss";

const Header = ({
  totalAmountOfPoints,
  name,
  email,
}: {
  totalAmountOfPoints: number;
  name: string;
  email: string;
}) => {
  const { t } = useTranslation();
  const { firebaseUser } = useAppContext();
  const [userLevel, setUserLevel] = React.useState(1);
  const [userLevelName, setUserLevelName] = React.useState("");
  const [
    piggyLoyaltyTotalCreditsReceived,
    setPiggyLoyaltyTotalCreditsReceived,
  ] = React.useState(0);
  const [nextLevel, setNextLevel] = React.useState(0);
  const levelSvgArray = [
    level1Svg,
    level2Svg,
    level3Svg,
    level4Svg,
    level5Svg,
    level6Svg,
  ];
  const { data: userLevelData, loading } = useGetUserLevelByEmailQuery({
    variables: {
      email,
    },
  });

  useEffect(() => {
    if (userLevelData) {
      setUserLevel(userLevelData?.getUserLevelByEmail.level);
      setUserLevelName(userLevelData?.getUserLevelByEmail.levelName);
      setPiggyLoyaltyTotalCreditsReceived(
        userLevelData?.getUserLevelByEmail.piggyLoyaltyTotalCreditsReceived
      );
      setNextLevel(userLevelData?.getUserLevelByEmail.nextLevel);
    }
  }, [userLevelData]);

  const getCardUrl = () => {
    window.location.assign(
      `${process.env.REACT_APP_REMOTE_URL}/api/wallets/pass/download/${firebaseUser?.uid}`
    );
  };

  // Hack for showing image on MacOS devices.
  useEffect(() => {
    if (navigator.vendor === "Apple Computer, Inc.") {
      document
        .querySelector("#bg-image")
        ?.classList.replace("bg-fixed", "bg-scroll");
    }
  }, []);

  console.log("totalAmountOfPoints", totalAmountOfPoints);
  console.log("userLevelName", userLevelName);

  const nextLevelPointsText =
    nextLevel > 0
      ? `${piggyLoyaltyTotalCreditsReceived}/${
          (piggyLoyaltyTotalCreditsReceived || 0) + (nextLevel || 0) - 1
        } points till next level`
      : `${piggyLoyaltyTotalCreditsReceived} points you have reached the max level`;

  return (
    <div id="home">
      <div className="welcome-block text-center">
        <></>
      </div>

      <div
        id="bg-image"
        className="d-flex align-items-center justify-content-center"
      >
        <div className="points-block text-center">
          <p className="fs-4 fw-bold mt-4 mb-0 text-light-shadow">
            {t("welcome", { ns: "common" })},
          </p>
          <p className="fs-3 fw-light text-light-shadow">{name}</p>
          <img
            className="level-svg"
            style={{ width: "150px" }}
            src={levelSvgArray[userLevel - 1]}
            alt={`Level${userLevel}svg`}
          />
          <p className="points-p mb-0 fw-bold text-light-shadow">
            Level {userLevel}
          </p>
          <p className="fs-3 fw-light text-light-shadow">{userLevelName}</p>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "60%",
                borderRadius: 10,
                height: 10,
              }}
            >
              <div
                style={{
                  backgroundColor: "#63A197",
                  width: `${
                    userLevelData
                      ? Math.round(
                          (piggyLoyaltyTotalCreditsReceived /
                            ((piggyLoyaltyTotalCreditsReceived || 0) +
                              (nextLevel || 0) -
                              1)) *
                            100
                        )
                      : 0
                  }%`,
                  borderRadius: 10,
                  height: "100%",
                }}
              >
                <></>
              </div>
            </div>
          </div>
          <p
            className="fs-5 fw-light text-light-shadow"
            style={{ fontSize: 14 }}
          >
            {!loading ? `${nextLevelPointsText}` : "Loading..."}
          </p>
        </div>
      </div>

      <div>
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(248,248,248,0.7"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(248,248,248,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(248,248,248,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#f8f8f8" />
          </g>
        </svg>
      </div>

      <div
        id="wallet-block"
        className="mx-0 row d-flex align-items-center justify-content-center"
      >
        <div className="wallet-div col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 text-center justify-content-center">
          <button className="btn-none" type="button" onClick={getCardUrl}>
            <img className="col-8 my-2" src={wallet} alt="wallet" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
