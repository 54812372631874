import React from "react";

import { Navbar, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/img/hetlogo.svg";
import { ReactComponent as ResetIcon } from "../../assets/img/Icon-open-reload.svg";
// import { ReactComponent as Line } from "../../assets/img/Line-1.svg";

import "./NavbarComponent.scss";

const NavbarComponet = ({ backIcon }: { backIcon: boolean }) => {
  const refreshInfo = () => {
    window.location.assign("/interface");
    console.log("backIcon", backIcon);
  };

  return (
    <Navbar
      expand="lg"

      // style={{
      //   background: `url(${NavBg})`,
      //   backgroundSize: "cover"
      // }}
    >
      {/* <img className="position-absolute bg-graphic" src={NavBg} alt="bg" /> */}
      <Container className="w-100 d-flex justify-content-between align-items-center">
        <Link to="/">
          <Button
            variant="d"
            className="border-0 text-main-text btn-reset text-uppercase"
            onClick={refreshInfo}
          >
            <span className="bg-white d-inline-block p-2 border-r-5 me-3 shadow-b">
              <ResetIcon />
            </span>
            <span className="d-none d-sm-inline-block ">Restart</span>
          </Button>
        </Link>
        <Navbar.Brand className="mx-auto position-sm-static position-absolute">
          <Link to="/">
            <Logo />
          </Link>
        </Navbar.Brand>
        <Navbar.Brand className="mx-auto position-sm-static position-absolute">
          <Link to="/">
            <Logo />
          </Link>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default NavbarComponet;
