import React from "react";

const DivLoading = (props: { loadingText?: string | undefined }) => {
  const { loadingText } = props;
  return (
    <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <p className="ml-2">{loadingText || "Loading..."}</p>
    </div>
  );
};

DivLoading.defaultProps = {
  loadingText: "Loading...",
};

export default DivLoading;
