import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../utils/contextLib";
import ChangeAccountModal from "../../Modal/ChangeAccountModal";
import DeleteAccountModal from "../../Modal/DeleteAccountModal";

const MyAccount = ({
  firstName,
  lastName,
  phoneNumber,
}: {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);
  const { firebaseUser } = useAppContext();

  return (
    <div
      id="account"
      className="bg-light text-center p-5 d-flex justify-content-center"
    >
      <div className="col-12 col-md-8">
        <p className="fs-4 fw-bold">{t("My account", "common")}</p>
        <table className="col-12 my-6">
          <tbody>
            <tr className="border-bottom d-flex justify-content-between py-2">
              <td className="fw-bold">{t("Name", "common")}:</td>
              <td className="text-end">{`${firstName} ${lastName}`}</td>
            </tr>
            <tr className="border-bottom d-flex justify-content-between py-2">
              <td className="fw-bold">{t("Email", "common")}:</td>
              <td className="text-end">{firebaseUser!.email} </td>
            </tr>
            <tr className="border-bottom d-flex justify-content-between py-2">
              <td className="fw-bold">{t("Phone", "common")}:</td>
              <td className="text-end">{phoneNumber} </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-5">
          <button
            onClick={() => setIsDeleteAccountModalOpen(true)}
            type="button"
            className="btn-none px-5 py-2 text-danger text-decoration-underline"
          >
            {t("Delete Account", "common")}
          </button>
          <button
            onClick={() => setIsModalOpen(true)}
            type="button"
            className="btn btn-dark bg-black px-3 py-2"
          >
            {t("Update Profile", "common")}
          </button>
        </div>
      </div>
      {isModalOpen && (
        <ChangeAccountModal
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          accountData={{
            firstName,
            lastName,
            phoneNumber,
            email: firebaseUser!.email as string,
          }}
        />
      )}

      {isDeleteAccountModalOpen && (
        <DeleteAccountModal
          isOpen={isDeleteAccountModalOpen}
          setIsModalOpen={setIsDeleteAccountModalOpen}
          emailAddress={firebaseUser?.email as string}
        />
      )}
    </div>
  );
};

export default MyAccount;
