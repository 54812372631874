import React from "react";
import "./UnknownResponse.scss";

const UnknownResponse = (props: { orderIdBlock: JSX.Element | null }) => {
  const { orderIdBlock } = props;

  return (
    <div id="unknownResponse-block">
      <div className="cardBox-head__alert pt-1 px-3 text-center pb-2">
        <h5 className="text-white pb-2">Betaling herkend</h5>{" "}
        <h5 className="text-main-text pt-1">
          First use <br /> Not connected to a member
        </h5>
      </div>

      {orderIdBlock}
    </div>
  );
};

export default UnknownResponse;
