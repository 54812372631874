import React from "react";

const NumberPadKey = (props: { children: string; onClick: () => void }) => {
  const { children, onClick } = props;

  return (
    <button
      className="bg-light btn-circle border-0"
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default NumberPadKey;
