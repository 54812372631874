import React from "react";

import { Link } from "react-router-dom";
import "./PageNotFound.scss";

const PageNotFound = () => (
  <div
    id="pageNoteFound-div"
    className="d-flex flex-column justify-content-center align-items-center"
  >
    <h3 className="">Page Not Found</h3>
    <p className="">If problem persists, please contact support</p>
    <button type="button" className="btn btn-lg btn-dark text-center">
      <Link className="text-light text-decoration-none" to="/member">
        <div className="">Home</div>
      </Link>
    </button>
  </div>
);

export default PageNotFound;
