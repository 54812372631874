/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

import { getBackendUri, getWebsocketUri } from "../utils/getEnv";

const httpLink = createHttpLink({
  uri: `${getBackendUri()}/graphql`,
  credentials: "include",
});

const wsLink = new WebSocketLink({
  uri: `${getWebsocketUri()}`,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: localStorage.getItem("twelve-token"),
    },
  },
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("twelve-token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )
    );

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    console.log({ networkError });
  }
});

const client = new ApolloClient({
  link: from([authLink, errorLink, splitLink]),
  cache: new InMemoryCache(),
});

interface Props {
  children: React.ReactNode;
}
const Client = (props: Props) => {
  const { children } = props;
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Client;
