import React from "react";

import Faq from "../components/FAQ/Faq";
import PageLoading from "../components/Loading/PageLoading";
import Activity from "../components/MembersOverviewComponents/Activity/Activity";
import Footer from "../components/MembersOverviewComponents/Footer/Footer";
import Header from "../components/MembersOverviewComponents/Header/Header";
import MembersNavbar from "../components/MembersOverviewComponents/MembersNavbar/MembersNavbar";
import MyAccount from "../components/MembersOverviewComponents/MyAccount/MyAccount";
import Stores from "../components/MembersOverviewComponents/StoresSection/Stores";
import TextSection from "../components/MembersOverviewComponents/TextSection/TextSection";
// import ProductSlider from "../components/ProductsSlider/Slider";
import RewardsSlider from "../components/RewardsSlider/Slider";
import {
  useGetUserOverviewDataQuery,
  useGetOverviewLevelRewardsByEmailQuery,
} from "../graphql/__generated__/graphql_types";
import useLogoutFirebase from "../hooks/LogoutHook";
import { useAppContext } from "../utils/contextLib";

const MemberOverviewPage = () => {
  const { firebaseUser } = useAppContext();
  const { data, loading } = useGetUserOverviewDataQuery({
    variables: {
      email: firebaseUser!.email!,
    },
  });

  const { data: rewards, loading: rewardsLoading } =
    useGetOverviewLevelRewardsByEmailQuery({
      variables: {
        email: firebaseUser!.email!,
      },
    });
  const [logOutFromFirebase] = useLogoutFirebase();

  if (loading) return <PageLoading />;

  if (data === undefined) {
    logOutFromFirebase();
  }

  return (
    <>
      <MembersNavbar />
      <Header
        totalAmountOfPoints={data!.getUserOverviewData.totalAmountOfPoints}
        name={data!.getUserOverviewData.firstName}
        email={firebaseUser!.email!}
      />
      {rewardsLoading ? (
        <p>loading...</p>
      ) : (
        <RewardsSlider
          levleRewards={rewards?.getOverviewLevelRewardsByEmail || []}
        />
      )}

      <Activity points={data!.getUserOverviewData.Points} />
      <TextSection />
      <Stores />
      {/* <ProductSlider /> */}
      <MyAccount
        firstName={data!.getUserOverviewData.firstName}
        lastName={data!.getUserOverviewData.lastName}
        phoneNumber={data!.getUserOverviewData.phoneNumber}
      />
      <Faq />
      <Footer />
    </>
  );
};

export default MemberOverviewPage;
