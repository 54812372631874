import React from "react";

import "./Footer.scss";

import { useTranslation } from "react-i18next";

import logo from "../../../assets/logo.png";
import loyyologo from "../../../assets/loyyologo.png";

const Footer = () => {
  const { i18n } = useTranslation();
  const changeLanguageHandler = () => {
    const origin = localStorage.getItem("lng") || "nl";
    if (origin === "nl") {
      i18n.changeLanguage("en");
      localStorage.setItem("lng", "en");
    } else {
      i18n.changeLanguage("nl");
      localStorage.setItem("lng", "nl");
    }
  };

  return (
    <div id="footer">
      <div className="store-information d-flex py-5 flex-column align-items-center justify-content-center">
        <div onClick={changeLanguageHandler} role="button" tabIndex={0}>
          <img src={logo} width="120px" alt="logo" />
        </div>
        <div className="text-center">
          <p className="">Het Koekemannetje</p>
          <p className="">Runstraat 3</p>
          <p className="">1016 GJ Amsterdam</p>
          <p className="">tel: 020-775 05 10</p>
          <a href="https://www.hetkoekemannetje.nl" className="text-dark">
            www.hetkoekemannetje.nl
          </a>
        </div>
      </div>
      <div className="powered-information d-flex py-2 justify-content-center">
        <a
          className="text-decoration-none"
          href="https://www.loyyo.nl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="d-flex py-2 align-items-center">
            <p className="my-0 mx-2 text-dark">Powered by</p>
            <div className="d-flex flex-column justify-content-center mx-2">
              <img alt="loyyo logo" src={loyyologo} width="120px" />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Footer;
