import React, { useEffect, useState } from "react";

import Carousel from "nuka-carousel";

import RewardCard from "./Reward";

import "./Slider.scss";

const RewardsSlider = (props: any) => {
  const { levleRewards } = props;
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [slideWidth, setSlideWidth] = useState(350);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600) {
        setSlidesToShow(3);
        setSlideWidth(365);
      } else if (window.innerWidth > 1000) {
        setSlidesToShow(2);
        setSlideWidth(365);
      } else {
        setSlidesToShow(1);
        setSlideWidth(350);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderedRewards = levleRewards.map((reward: any) => (
    <RewardCard
      key={reward.id}
      level={reward.level}
      levelName={reward.levelName}
      title={reward.displayName}
      discription={reward.discription}
      rewardImage={reward.imageUrl}
      redeemed={reward.redeemed}
    />
  ));

  const carouselProps = {
    defaultControlsConfig: {
      pagingDotsStyle: {
        fill: "white",
      },
    },
    slidesToShow,
    cellSpacing: slidesToShow > 1 ? 15 : 0,
    initialSlideHeight: 440,
    renderCenterLeftControls: null,
    renderCenterRightControls: null,
    heightMode: "current" as "first" | "current" | "max",
  };
  return (
    <div id="stores" className="bg-koekemannetje py-5">
      <p className="fs-4 text-dark fw-bold text-center">Rewards</p>
      <div
        className="carousel-block my-5"
        style={{ width: `${slideWidth * slidesToShow}px` }}
      >
        <Carousel {...carouselProps}>{renderedRewards}</Carousel>
      </div>
    </div>
  );
};

export default RewardsSlider;
