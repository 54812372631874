import React from "react";

import { ReactComponent as Icons2 } from "../../assets/img/icon22.svg";
import "react-toggle/style.css";
import { useGetUserLevelByEmailQuery } from "../../graphql/__generated__/graphql_types";
import "./RedeemRewardInfo.scss";

const RedeemRewardInfo = ({
  customerData,
}: // amountOfPoints,
{
  customerData: {
    email: string;
    firstName: string;
    lastName: string;
    totalAmountOfPoints: number;
  };
  // amountOfPoints: string;
}) => {
  const userLevelData = useGetUserLevelByEmailQuery({
    variables: {
      email: customerData.email,
    },
  }).data?.getUserLevelByEmail;

  return (
    <div id="redeemRewardInfo" className="">
      <div className="d-flex flex-wrap justify-content-center align-items-center Div-centers-reedem">
        <div className="cardBox mx-0 storecard addpoints mr-0 mr-md-4">
          <div className="cardBox-head">
            <h1 className="text-main-text pt-2">{`${customerData?.firstName} ${customerData?.lastName}`}</h1>
            <Icons2 className="icon-center cartoon-img" />
            <ul className="list-unstyled d-flex justify-content-between flex-wrap mt-4 pb-2 mx-auto">
              <li className="text-center icon-center">
                <span className="d-block text-main-text font-weight-light py-1">
                  Levels
                </span>
              </li>
              <div
                style={{
                  color: "black",
                  fontSize: 18,
                  width: 229,
                  fontWeight: 600,
                }}
                className="bg-light screen-value border-r-5 shadow-b py-3 px-3 text-center mb-2 text-centerko"
              >
                {userLevelData?.levelName}
              </div>
            </ul>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  width: "60%",
                  borderRadius: 10,
                  height: 10,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#63A197",
                    width: `${
                      userLevelData
                        ? Math.round(
                            (userLevelData?.piggyLoyaltyTotalCreditsReceived /
                              ((userLevelData?.piggyLoyaltyTotalCreditsReceived ||
                                0) +
                                (userLevelData?.nextLevel || 0) -
                                1)) *
                              100
                          )
                        : 0
                    }%`,
                    borderRadius: 10,
                    height: "100%",
                  }}
                >
                  <></>
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <p style={{ fontSize: 14 }}>
                {userLevelData?.nextLevel || -1 >= 0
                  ? `${userLevelData?.piggyLoyaltyTotalCreditsReceived}/${
                      (userLevelData?.piggyLoyaltyTotalCreditsReceived || 0) +
                      (userLevelData?.nextLevel || 0) -
                      1
                    } points till next level`
                  : "Loading..."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemRewardInfo;
