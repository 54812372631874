export const devCloud = {
  apiKey: "AIzaSyAH3w_9s3hv5yDimFixKZF3cbopvbL-Jx4",
  authDomain: "koekemannetje-dev.firebaseapp.com",
  projectId: "koekemannetje-dev",
  storageBucket: "koekemannetje-dev.appspot.com",
  messagingSenderId: "75381065359",
  appId: "1:75381065359:web:5d34fcc2c891be693892fa",
};
export const prodCloud = {
  apiKey: "AIzaSyCdrWyqQHpuJ4OFh-xX-b7HC2RHQrIKEOc",
  authDomain: "koekemannetje-prod.firebaseapp.com",
  projectId: "koekemannetje-prod",
  storageBucket: "koekemannetje-prod.appspot.com",
  messagingSenderId: "642240813083",
  appId: "1:642240813083:web:d04f020c04e85cf60680be",
};

export const localDev = {
  apiKey: "AIzaSyB5-7bcjtINqXb3waoWismIHls2EORjSHQ",
  authDomain: "koekemannetje-local-dev.firebaseapp.com",
  projectId: "koekemannetje-local-dev",
  storageBucket: "koekemannetje-local-dev.appspot.com",
  messagingSenderId: "460375918164",
  appId: "1:460375918164:web:611a91544bab821c1dcfc1",
};

export const testEnv = {
  apiKey: "AIzaSyB70W-QKoTZL4fcSC7W2xn9O4zO3cYxm20",
  authDomain: "koekemannetje-test-env.firebaseapp.com",
  projectId: "koekemannetje-test-env",
  storageBucket: "koekemannetje-test-env.appspot.com",
  messagingSenderId: "425064952069",
  appId: "1:425064952069:web:44d81386338207e17d876c",
};
