import React from "react";

import "./RewardItem.scss";
import Redeemed from "../Redeemed/Redeemed";

const RewardItem = (props: {
  uuid: string;
  email: string;
  imageUrl: string;
  name: string;
  description: string;
  redeemable: boolean;
  handleRedeemSubmit: (rewardUuid: string, email: string) => Promise<Boolean>;
}) => {
  const {
    uuid,
    email,
    imageUrl,
    name,
    description,
    redeemable,
    handleRedeemSubmit,
  } = props;

  let displayName = name;
  let levelName = name;
  const regexName = /^(Level\d+) - (.*)$/.exec(name);
  if (regexName !== null) {
    [, levelName, displayName] = regexName;
  }

  return (
    <div className={`reward-card ${redeemable && "card-hover"}`}>
      <div className="img_div" style={{ width: "80%", height: "50%" }}>
        <div className="level-label">{levelName}</div>
        <p style={{ display: "none", fontSize: 12 }}>
          {redeemable && (
            <button
              onClick={() => handleRedeemSubmit(uuid, email)}
              type="button"
              className="btn mt-2"
            >
              Redeem
            </button>
          )}
        </p>
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 7,
            objectFit: "contain",
          }}
          src={imageUrl}
          alt="card1"
        />
      </div>
      <span
        className="w-75 text-main-text font-weight-light pt-1 text-center"
        style={{ fontSize: 14, marginTop: 5, textAlign: "center" }}
      >
        {displayName}
      </span>
      <span style={{ fontSize: 12, color: "#c4d11f" }}>{description}</span>
      {!redeemable && <Redeemed />}
    </div>
  );
};
export default RewardItem;
