import React from "react";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useGetLevelRewardsByEmailQuery,
  useRedeemLevelRewardMutation,
} from "../../graphql/__generated__/graphql_types";
import DivLoading from "../Loading/DivLoading";
import RewardItem from "./RewardItem";

const Rewards = () => {
  const { state } = useHistory().location as any;
  const [redeemLevelReward, { loading, error }] =
    useRedeemLevelRewardMutation();

  const handleRedeemSubmit = async (
    rewardUuid: string,
    email: string
  ): Promise<Boolean> => {
    const result = await redeemLevelReward({
      variables: {
        rewardUuid,
        email,
      },
    });

    if (error) {
      toast.error(`something went wrong try it again`);
      return false;
    }

    if (result.data?.redeemLevelReward.status) {
      toast.success(`redeem successfully`);
      setTimeout(() => {
        window.location.assign("/interface/redeem-rewards");
      }, 2000);
    } else {
      toast.error(`${JSON.stringify(result.data?.redeemLevelReward.message)}`);
    }

    console.log("result", result);
    return true;
  };

  const { data, loading: loadingRewards } = useGetLevelRewardsByEmailQuery({
    variables: {
      email: state.customerData?.email,
    },
  });

  const rewards = data?.getLevelRewardsByEmail || [];

  return loading || loadingRewards ? (
    <div className="h-100">
      <DivLoading />
    </div>
  ) : (
    <div id="reward">
      <div style={{ marginBottom: "13%" }}>
        <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
          {rewards.map((reward) => (
            <RewardItem
              key={reward.uuid}
              uuid={reward.uuid}
              email={state.customerData?.email}
              name={reward.name}
              description={reward.description}
              imageUrl={reward.imageUrl}
              redeemable={reward.redeemable}
              handleRedeemSubmit={handleRedeemSubmit}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rewards;
