import React from "react";

import { FaCheck } from "react-icons/fa";
import { HiArrowLeft } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";

import NumberPadKey from "./NumberPadKey/NumberPadKey";
import "./NumPad.scss";

const NumberPad = (props: {
  displayPriceInCents: string;
  setDisplayPrice: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: () => void;
  displayInEuro: boolean;
}) => {
  const { displayPriceInCents, displayInEuro, handleSubmit, setDisplayPrice } =
    props;

  const displayPriceToFormattedNumber = displayInEuro
    ? (parseFloat(displayPriceInCents || "0") / 100).toFixed(2)
    : parseInt(displayPriceInCents, 10) || 0;

  const savePayment = () => {
    if (
      displayInEuro &&
      parseFloat(displayPriceToFormattedNumber as string) < 1
    ) {
      toast.error("Het bedrag is te laag. Voer minimaal 1,00 euro in", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    handleSubmit();
  };

  return (
    <div id="numPad">
      <div className="display-price-block badge rounded-pill text-light">
        {displayInEuro && "€"} {displayPriceToFormattedNumber}
      </div>

      <div className="number-pad-block d-flex flex-column align-items-between">
        <div className="number-pad-row row">
          <div className="col-3 d-flex justify-content-center">
            <NumberPadKey
              onClick={() => setDisplayPrice(displayPriceInCents.concat("1"))}
            >
              1
            </NumberPadKey>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <NumberPadKey
              onClick={() => setDisplayPrice(displayPriceInCents.concat("2"))}
            >
              2
            </NumberPadKey>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <NumberPadKey
              onClick={() => setDisplayPrice(displayPriceInCents.concat("3"))}
            >
              3
            </NumberPadKey>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <button
              type="button"
              className="btn-circle btn-warning"
              onClick={() => setDisplayPrice(displayPriceInCents.slice(0, -1))}
            >
              <HiArrowLeft size={35} color="white" />
            </button>
          </div>
        </div>
        <div className="number-pad-row row">
          <div className="col-3 d-flex justify-content-center">
            <NumberPadKey
              onClick={() => setDisplayPrice(displayPriceInCents.concat("4"))}
            >
              4
            </NumberPadKey>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <NumberPadKey
              onClick={() => setDisplayPrice(displayPriceInCents.concat("5"))}
            >
              5
            </NumberPadKey>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <NumberPadKey
              onClick={() => setDisplayPrice(displayPriceInCents.concat("6"))}
            >
              6
            </NumberPadKey>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <button
              className="btn-circle btn-danger"
              onClick={() => setDisplayPrice("0")}
              type="button"
            >
              <IoMdClose size={45} color="white" />
            </button>
          </div>
        </div>

        <div className="number-pad-row row">
          <div className="col-3 d-flex justify-content-center">
            <NumberPadKey
              onClick={() => setDisplayPrice(displayPriceInCents.concat("7"))}
            >
              7
            </NumberPadKey>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <NumberPadKey
              onClick={() => setDisplayPrice(displayPriceInCents.concat("8"))}
            >
              8
            </NumberPadKey>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <NumberPadKey
              onClick={() => setDisplayPrice(displayPriceInCents.concat("9"))}
            >
              9
            </NumberPadKey>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <button
              type="button"
              className="btn-enter-top"
              onClick={savePayment}
            >
              <FaCheck style={{ height: "30px" }} color="white" size={35} />
            </button>
          </div>
        </div>

        <div className="number-pad-row row">
          <div className="col-6 d-flex align-items-end">
            <button
              className="bg-light rounded-pill border-0 btn-circle"
              style={{ height: "90%", width: "100%" }}
              type="button"
              onClick={() => setDisplayPrice(displayPriceInCents.concat("0"))}
            >
              0
            </button>
          </div>
          <div className="col-6 d-flex align-items-end">
            <button
              type="button"
              className="btn-enter-bottom"
              onClick={savePayment}
            >
              {" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberPad;
