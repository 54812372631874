import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import NavbarComponent from "../components/NavbarComponent/NavbarComponent";
import NumberPad from "../components/NumberPad/NumPad";
import RedeemPointsInfo from "../components/RedeemPointsInfo/RedeemPointsInfo";
import { useRedeemPointsManuallyMutation } from "../graphql/__generated__/graphql_types";

import "./RedeemPoints.scss";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  totalAmountOfPoints: 0,
};

const RedeemPointsPage = () => {
  const [redeemPointsManually] = useRedeemPointsManuallyMutation();

  const [amountOfPoints, setAmountOfPoints] = useState("");
  const [customerData, setCustomerData] = useState(initialState);

  const history = useHistory();
  const { state } = useHistory().location as any;

  useEffect(() => {
    if (!state?.customerData?.email) history.push("/interface");

    if (state?.customerData) {
      setCustomerData({
        ...state.customerData,
        totalAmountOfPoints: state.totalAmountOfPoints,
      });
    }
  }, [history, state.customerData, state.totalAmountOfPoints]);

  const handleSubmit = () => {
    redeemPointsManually({
      variables: {
        amount: amountOfPoints,
        email: customerData.email,
      },
    })
      .then(({ data }) => {
        toast.success(
          `${data?.redeemPointsManually.amount} points were redeem from ${customerData.firstName}!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        history.push("/interface", undefined);
      })
      .catch((error) => {
        toast.error(`Er ging iets mis... ${JSON.stringify(error)}`);
      });
  };

  return (
    <>
      <div id="redeemPoints">
        <NavbarComponent backIcon />

        <div className="main-block row col-12 pb-5 mx-0 d-flex justify-content-center justify-content-md-evenly align-items-center">
          <div className="block col-10 col-md-5 mt-5 mt-lg-0">
            <RedeemPointsInfo
              amountOfPoints={amountOfPoints}
              customerData={customerData}
            />
          </div>
          <div className="block col-10 col-md-5 mt-5 mt-lg-0 d-flex flex-column justify-content-between">
            <NumberPad
              setDisplayPrice={setAmountOfPoints}
              displayPriceInCents={amountOfPoints}
              handleSubmit={handleSubmit}
              displayInEuro={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RedeemPointsPage;
