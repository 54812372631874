import React from "react";

const PageLoading = () => (
  <div
    style={{ height: "100vh" }}
    className="d-flex text-light bg-koekemannetje justify-content-center align-items-center"
  >
    <div className="spinner-border text-dark me-3" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <div className="fs-3 text-dark">Loading...</div>
  </div>
);

export default PageLoading;
