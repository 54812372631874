import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const RedeemRewardButton = ({
  customerData,
  enabled,
  totalAmountOfPoints,
}: {
  customerData: { firstName: string; lastName: string; email: string };
  enabled: boolean;
  totalAmountOfPoints: number;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <button
      type="button"
      disabled={!enabled}
      className="btn btn-sq-lg"
      onClick={() =>
        history.push("/interface/redeem-rewards", {
          customerData,
          totalAmountOfPoints,
        })
      }
    >
      <div className="text-light">{t("Redeem Rewards", "common")}</div>
    </button>
  );
};

export default RedeemRewardButton;
