/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { ToastContainer } from "react-toastify";

import ApolloClient from "./Apollo/ApolloProvider";
import PageLoading from "./components/Loading/PageLoading";
import firebase from "./config/firebase";
import Routes from "./Routes";
import { AppContext } from "./utils/contextLib";
import "./App.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { withTranslation } from "react-i18next";

function App() {
  const [firebaseUser, loading] = useAuthState(firebase.auth());
  const [isAuthenticated, userHasAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (firebaseUser) {
      userHasAuthenticated(true);
    }
  }, [firebaseUser]);

  if (loading) return <PageLoading />;
  // if (error) return <ErrorPage />;

  return (
    <AppContext.Provider
      value={{ isAuthenticated, userHasAuthenticated, firebaseUser }}
    >
      <ApolloClient>
        <ToastContainer />
        <Routes />
      </ApolloClient>
    </AppContext.Provider>
  );
}

export default App;
