/* eslint-disable no-nested-ternary */
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";

import { useCombobox } from "downshift";
import { AiOutlineUserAdd } from "react-icons/ai";

import {
  User,
  useSearchUsersLazyQuery,
} from "../../graphql/__generated__/graphql_types";
import ConnectModal from "../Modal/ConnectModal";
import CreateUserModal from "../Modal/CreateUserModal";
import "./SearchBox.scss";

const SearchBox = forwardRef(
  (
    props: {
      orderId: string | undefined | null;
      cardAlias: string | undefined | null;
      setUserFromSearch: React.Dispatch<
        React.SetStateAction<{
          firstName: string;
          lastName: string;
          email: string;
        }>
      >;
      isCardAlreadyConnected: boolean;
    },
    ref: any
  ) => {
    const searchRef = useRef<any>();

    // const itemToString = (item: any) => (item ? item.name : "");

    const { cardAlias, orderId, setUserFromSearch, isCardAlreadyConnected } =
      props;
    const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
    const [isCreateUserModalOpeen, setIsCreateUserModalOpen] = useState(false);
    const [users, setUsers] = useState<User[]>([]);

    const [searchUsers, { data, loading, error }] = useSearchUsersLazyQuery({
      fetchPolicy: "cache-and-network",
    });

    const [user, setUser] = useState<User>();

    useEffect(() => {
      if (data?.searchFilterUsers) {
        setUsers(data?.searchFilterUsers);
      }
    }, [data, data?.searchFilterUsers]);

    const openConnectModal = (userData: User) => {
      setIsConnectModalOpen(true);
      setUser(userData);
    };

    const {
      isOpen,
      getInputProps,
      getMenuProps,
      getItemProps,
      getComboboxProps,
      selectedItem,
      openMenu,
      reset,
    } = useCombobox({
      items: users,
      // itemToString,
      onInputValueChange: async ({ inputValue }) => {
        if (!inputValue) return;
        let phone;
        if (inputValue && inputValue.length < 5) return;

        if (inputValue?.startsWith("06")) {
          phone = `+316${inputValue.slice(2)}`;
        } else phone = inputValue;

        searchUsers({
          variables: {
            filter: phone || " ",
          },
        });
      },
      onSelectedItemChange: () => {
        reset();
      },
    });

    // This is to clear the state from parent when payment is coming in.
    useImperativeHandle(ref, () => ({
      clearState: () => {
        searchRef.current = reset();
      },
    }));

    const selectFirstUser = () => {
      if (data?.searchFilterUsers.length === 1) {
        setUserFromSearch({
          email: data?.searchFilterUsers[0].email,
          firstName: data?.searchFilterUsers[0].firstName,
          lastName: data?.searchFilterUsers[0].lastName,
        });
        reset();
      }
    };

    const searchPress = async (event: KeyboardEvent) => {
      if (event.keyCode === 13) {
        if (data?.searchFilterUsers.length === 1) {
          selectFirstUser();
        } else {
          const userId = (event.target as any)?.value || "";
          searchUsers({
            variables: {
              filter: userId,
            },
          }).then((queryData) => {
            const searchFilterUsers = queryData.data?.searchFilterUsers || [];
            if (searchFilterUsers.length === 1) {
              setUserFromSearch({
                email: searchFilterUsers[0].email || "",
                firstName: searchFilterUsers[0].firstName || "",
                lastName: searchFilterUsers[0].lastName || "",
              });
              reset();
            }
          });
        }
      }
    };

    useEffect(() => {
      if (selectedItem) {
        setUserFromSearch({
          email: selectedItem.email,
          firstName: selectedItem.firstName,
          lastName: selectedItem.lastName,
        });
      }
    }, [selectedItem, setUserFromSearch]);

    return (
      <div id="searchBox">
        <div className="flex search-bar" {...getComboboxProps()}>
          <div className="input-group">
            <input
              className="form-control py-2 border-0 fst-italic mx-2"
              type="tel"
              placeholder="search by phone"
              {...getInputProps({
                onFocus: () => {
                  if (!isOpen) {
                    openMenu();
                  }
                },
              })}
              onKeyDown={searchPress}
            />
            <span className="input-group-append">
              <div className="input-group-text bg-transparent">
                <i className="fa fa-search" />
              </div>
            </span>
          </div>
        </div>

        <ul
          className={`bg-light list-group ${isOpen ? "mt-2 select-block" : ""}`}
          {...getMenuProps()}
        >
          {!isOpen ? null : (
            <div className="content-ul row m-0">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  Loading....
                </div>
              ) : error ? (
                <>
                  <div className="">Error....</div>
                  <div className="">{JSON.stringify(error)}</div>
                </>
              ) : users.length ? (
                <div className="d-flex flex-column justify-content-start p-0">
                  {users.map((item, index) => (
                    <li
                      key={`${item.email}${item.phoneNumber}`}
                      className="list-group-item list-group-item-action d-flex"
                      {...getItemProps({
                        item,
                        index,
                        disabled: !!orderId,
                      })}
                    >
                      <div className="d-flex align-items-center justify-content-evenly col-9">
                        <div className="fs-6">{`${item.firstName} ${item.lastName}`}</div>
                        <div className="fs-6"> {item.phoneNumber} </div>
                      </div>
                      <div className="col-3 d-flex align-items-conter">
                        {orderId && !isCardAlreadyConnected && (
                          <button
                            className="btn-connect"
                            type="button"
                            onClick={() => openConnectModal(item)}
                          >
                            Connect
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
                </div>
              ) : getInputProps().value.length < 5 ? (
                <div className="d-flex justify-content-center align-items-center">
                  <p className="">add at least 5 characters...</p>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="text-center">
                    No member was found with <br /> {getInputProps().value}
                  </p>
                  <button
                    type="button"
                    className="btn-invitation"
                    onClick={() => setIsCreateUserModalOpen(true)}
                  >
                    <AiOutlineUserAdd size={25} className="" />
                    <br />
                    Send invitation
                  </button>
                </div>
              )}
            </div>
          )}
        </ul>
        {isConnectModalOpen && (
          <ConnectModal
            isOpen={isConnectModalOpen}
            closeModal={() => setIsConnectModalOpen(false)}
            userData={user}
            orderId={orderId}
            reset={reset}
          />
        )}
        {isCreateUserModalOpeen && (
          <CreateUserModal
            cardAlias={!isCardAlreadyConnected ? cardAlias : ""}
            closeModal={() => setIsCreateUserModalOpen(false)}
            isOpen={isCreateUserModalOpeen}
            phoneNumber={getInputProps().value}
            reset={reset}
          />
        )}
      </div>
    );
  }
);

SearchBox.displayName = "SearchBoxComponent";

export default SearchBox;
