import React from "react";

import { useTranslation } from "react-i18next";

import logo from "../assets/logo.png";
import Login from "../components/Login/Login";
import "./LoginPage.scss";

const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div id="loginPage-div" className="bg-koekemannetje">
        <div className="login-container container d-flex flex-column justify-content-center align-items-center">
          <div className="logo-img-block-sm d-block d-lg-none">
            <img className="logo-img" alt="logo" src={logo} />
          </div>
          <div className="main-block row">
            <div className="logo-block col-lg-6 d-lg-flex d-none justify-content-center align-items-center">
              <img alt="logo" src={logo} />
            </div>
            <div className="login-block col-12 col-lg-6 d-lg-flex flex-column bg-light px-3 py-5 px-md-5">
              <div className="col-12 text-center">
                <p className="fs-3 fw-bold">LOGIN</p>
                <p className="fs-6 text-muted">
                  {t("Enter your details for login", "common")}
                </p>
              </div>
              <div className="login-component">
                <Login />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
