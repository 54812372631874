import React, { useState } from "react";

import Toggle from "react-toggle";
import "react-toggle/style.css";

import "./RedeemPointsInfo.scss";

const RedeemPointsInfo = ({
  customerData,
  amountOfPoints,
}: {
  customerData: {
    email: string;
    firstName: string;
    lastName: string;
    totalAmountOfPoints: number;
  };
  amountOfPoints: string;
}) => {
  const [isCorrection, setIsCorrection] = useState<boolean>(false);
  const points = parseInt(amountOfPoints, 10) || 0;

  const handleChangeToggle = () => {
    setIsCorrection(!isCorrection);
  };

  return (
    <div id="redeemPointsInfo" className="">
      <div className="top-block text-light">
        <div className="information-icon-block">
          <div className="information-icon rounded-circle">
            <i className="fa-regular fa-circle-user fs-1" />
          </div>
          <p className="fs-5">&nbsp;</p>
        </div>

        <p className="fs-4 text-center text-light p-4">Redeem Points</p>
      </div>
      <div className="bottom-block bg-light">
        <div className="row d-flex justify-content-center">
          <div className="col-12 my-4 text-center">
            <h2>{`${customerData.firstName} ${customerData.lastName}`}</h2>
          </div>
          <div className="text-center">
            <div className="points-block fw-bold col-6 offset-3">
              {customerData.totalAmountOfPoints} - {points}
            </div>
            <p className="">points</p>
          </div>

          <div>
            <p className="mx-4 text-center">
              Type on the right side of the screen the{" "}
              <span className="fw-bold"> equivalent points </span>
              equivalent points you want to redeem/subtract from member.
            </p>
          </div>
        </div>

        <div>
          <p className="mx-4 text-center">
            Select below &quot;correctie / return&quot;, when this is not a
            regular transaction.
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <Toggle defaultChecked={false} onChange={handleChangeToggle} />
          <span className="mx-2">Correct / Return</span>
        </div>
      </div>
    </div>
  );
};

export default RedeemPointsInfo;
