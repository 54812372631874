import React from "react";

import StoreCard from "./StoreCard/StoreCard";

const Stores = () => (
  <div id="stores" className="bg-koekemannetje py-5">
    <p className="fs-4 fw-bold text-center">Kom naar onze winkel</p>
    <div className="my-5 d-flex justify-content-center">
      <StoreCard
        streetAndNumber="Runstraat 3"
        postcode="1016 GJ"
        city="Amsterdam"
        link="https://g.page/Koekemannetje?share"
      />
    </div>
  </div>
);

export default Stores;
