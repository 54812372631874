import React from "react";

import { Link } from "react-router-dom";

import "./CardConnectedPage.scss";

const CardConnectedPage = () => (
  <div
    id="cardConnectedPage-div"
    className="d-flex flex-column justify-content-center align-items-center"
  >
    <h3 className="">Card is connected</h3>
    <p className="">Return to your account here:</p>
    <button type="button" className="btn btn-lg btn-dark text-center">
      <Link className="text-light text-decoration-none" to="/member">
        <div className="">Home</div>
      </Link>
    </button>
  </div>
);

export default CardConnectedPage;
