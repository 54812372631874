import React from "react";

import { useTranslation } from "react-i18next";
import { Link as ReactScrollLink } from "react-scroll";

const TextSection = () => {
  const { t } = useTranslation();
  return (
    <div id="how">
      <div className="bg-light p-5 text-center d-flex justify-content-center">
        <div className="col-12 col-md-8">
          <p className="fs-4 my-3 fw-bold">{t("How to save points?")}</p>
          <p className="fs-5 text-secondary">
            {t("You can save points in different ways...")}
          </p>

          <ReactScrollLink className="text-light" to="faq" offset={-50}>
            <button type="button" className="btn btn-dark btn-lg py-3 my-8">
              {t("More information here")}
            </button>
          </ReactScrollLink>
        </div>
      </div>
    </div>
  );
};

export default TextSection;
