import { useHistory } from "react-router-dom";

import firebase from "../config/firebase";
import { useAppContext } from "../utils/contextLib";

const useLogoutFirebase = (): [() => void] => {
  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();

  const logOutFromFirebase = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("twelve-token");
        userHasAuthenticated(false);
        history.push("/login");
      });
  };

  return [logOutFromFirebase];
};

export default useLogoutFirebase;
