import React from "react";

import "./StoreCard.scss";

import maps from "../../../../assets/google-maps-logo.png";
import storeimage from "../../../../assets/storefront.jpeg";

type StoreCardProps = {
  streetAndNumber: string;
  postcode: string;
  city: string;
  link: string;
};

const StoreCard = ({
  streetAndNumber,
  postcode,
  city,
  link,
}: StoreCardProps) => (
  <div id="storeCard" className="card">
    <img className="store-front p-4" src={storeimage} alt="store front" />
    <div className="card-body p-4 d-flex align-items-end justify-content-between">
      <div className="">
        <h5 className="card-title">{streetAndNumber}</h5>
        <p className="m-0">{postcode}</p>
        <p>{city}</p>
      </div>
      <div>
        <a href={link} target="blank" className="text-decoration-none">
          <div className="text-center">
            <img src={maps} alt="google maps logo" />
            <p className="text-dark">Check on Maps</p>
          </div>
        </a>
      </div>
    </div>
  </div>
);

export default StoreCard;
