export function getBackendUri(): string {
  return process.env.REACT_APP_REMOTE_URL || "http://localhost:4001";
}

export function getWebsocketUri(): string {
  return process.env.REACT_APP_WS_URL || "ws://localhost:4001/graphql";
}

export const getFirebaseEnvironmentVariable = () =>
  process.env.REACT_APP_FIREBASE_ENV;
