import React from "react";

import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Points } from "../../../graphql/__generated__/graphql_types";

const Activity = ({ points }: { points: Points[] }) => {
  const { t, i18n } = useTranslation();
  const renderedPoints = points
    .slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    )
    .slice(0, 8)
    .map(({ amount, createdAt, id }) => (
      <tr key={id} className="border-bottom">
        <td>{new Date(createdAt).toLocaleDateString("NL")}</td>
        <td className="text-end">{amount}</td>
      </tr>
    ));

  return (
    <div
      id="activity"
      className="bg-koekemannetje p-5 d-flex justify-content-center"
    >
      <div className="col-12 col-sm-8 col-md-6">
        <p className="text-dark text-center fs-4 fw-bolder">
          {t("Account History", { ns: "common" })}
        </p>
        <Table borderless variant="koekemannetje">
          <thead>
            <tr className="justify-content-between">
              <th className="text-start" scope="col">
                {i18n.format(
                  t("date", { ns: "common", capitalize: true }),
                  "capitalize"
                )}
              </th>
              <th className="text-end" scope="col">
                {i18n.format(
                  t("points", { ns: "common", capitalize: true }),
                  "capitalize"
                )}
              </th>
            </tr>
          </thead>
          <tbody>{renderedPoints}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default Activity;
