import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ManualPointsButton = ({
  enabled,
  customerData,
  totalAmountOfPoints,
}: {
  customerData: {
    firstName: string;
    lastName: string;
    email: string;
  };
  enabled: boolean;
  totalAmountOfPoints: number;
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <button
      type="button"
      disabled={!enabled}
      className="btn btn-sq-lg"
      onClick={() =>
        history.push("/interface/add-points", {
          customerData,
          totalAmountOfPoints,
        })
      }
    >
      <div className="text-light">{t("Add Points", "common")}</div>
    </button>
  );
};

export default ManualPointsButton;
