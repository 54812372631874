import React from "react";

import "./Faq.scss";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const faqItems = [
    {
      questionId: 0,
      question: `How can I collect points?`,
      answer: `You can collect points during every purchase...`,
    },
    {
      questionId: 1,
      question: `Where can I find my balance?`,
      answer: `You find your balance in your account...`,
    },
    {
      questionId: 2,
      question: `What are the rewards for my points?`,
      answer: `We have special rewards for our members...`,
    },
    {
      questionId: 3,
      question: `How can I update my personal information?`,
      answer: `You can always change your personal information in...`,
    },
    {
      questionId: 4,
      question: `Can I add other payment methods to my account? If yes, how many can I add?`,
      answer: `Of course, you can add as many payment methods as you wish...`,
    },
    {
      questionId: 5,
      question: `How can I delete my account?`,
      answer: `We are sorry to see you go...`,
    },
    {
      questionId: 6,
      question: `How do you take care of privacy and my bank information?`,
      answer: `Our partners nor Loyyo have access to bank information of our members...`,
    },
    {
      questionId: 7,
      question: `I made a purchase...`,
      answer: `We would like to invite you to one of our stores...`,
    },
  ];

  const renderedFaqitem = faqItems.map((faqItem, index) => (
    <Accordion.Item key={faqItem.questionId} eventKey={index.toString()}>
      <Accordion.Header as="h1">
        {t(faqItem.question as any, { ns: "common" })}
      </Accordion.Header>
      <Accordion.Body className="bg-koekemannetje text-dark">
        {t(faqItem.answer as any, { ns: "common" })}
      </Accordion.Body>
    </Accordion.Item>
  ));

  return (
    <div
      className="bg-koekemannetje py-5 justify-content-center d-flex"
      id="faq"
    >
      <div className="col-10 col-md-8">
        <p className="text-dark fs-4 fw-bold text-center">
          {t("FAQ" as any, { ns: "common" })}
        </p>
        <div className="my-10">
          <Accordion>{renderedFaqitem}</Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
