import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, Redirect, useParams } from "react-router-dom";

import firebase from "../../config/firebase";
import {
  useFindUniqueCardQuery,
  useRegisterUserMutation,
} from "../../graphql/__generated__/graphql_types";
import { useAppContext } from "../../utils/contextLib";
import PageLoading from "../Loading/PageLoading";

import "./Register.scss";

interface ParamTypes {
  card: string;
}

const Register = () => {
  const { t } = useTranslation();
  // "Getting cardAlias first if exists"
  const { card } = useParams<ParamTypes>();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    password: "",
    customerKey: "",
    termsAgreed: false,
  });
  const [registerError, setRegisterError] = useState<string[] | string>([]);

  const { isAuthenticated, userHasAuthenticated } = useAppContext();

  const { data, loading: queryLoading } = useFindUniqueCardQuery({
    skip: !card,
    variables: {
      card,
    },
  });
  const [registerUser] = useRegisterUserMutation();

  useEffect(() => {
    if (data?.findUniqueCard) {
      setFormData((form) => ({
        ...form,
        customerKey: data.findUniqueCard!.cardAlias as string,
        phoneNumber: data.findUniqueCard!.phoneNumber as string,
      }));
    }
  }, [data]);

  if (queryLoading) return <PageLoading />;

  const loginAndRedirect = async () => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(formData.emailAddress, formData.password)
      .then(() => {
        localStorage.removeItem("emailForSignIn");
        userHasAuthenticated(true);
      });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterError([]);
    const { target } = event;
    const { name } = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {
      customerKey,
      emailAddress,
      firstName,
      lastName,
      password,
      phoneNumber,
      termsAgreed,
    } = formData;

    localStorage.setItem("emailForSignIn", emailAddress);

    await registerUser({
      variables: {
        emailAddress,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        phoneNumber,
        termsAgreed,
        password,
        customerKey,
      },
    })
      .then(async () => {
        // TODO: Redirect after succes.
        // localStorage.removeItem("cardHash");
        loginAndRedirect();
      })
      .catch((error) => {
        let message;
        if (
          error?.graphQLErrors[0]?.extensions?.exception?.response?.message
            ?.length
        ) {
          [message] =
            error.graphQLErrors[0].extensions.exception.response.message;
        } else if (
          error?.graphQLErrors[0]?.extensions?.exception?.code === "P2002"
        ) {
          message = "Dit telefoonnummer is al in gebruik.";
        } else {
          message = error.message;
        }

        setRegisterError(message);
      });
  };

  if (isAuthenticated) return <Redirect to="/member" />;

  return (
    <form
      onSubmit={handleSubmit}
      id="register-form"
      className="bg-light d-flex flex-column align-items-stretch justify-content-evenly"
    >
      <div className="input-block">
        <div className="col-12 d-flex my-2">
          <div className="col-6 pe-2">
            <label htmlFor="validationCustomFirstName" className="form-label">
              {t("FirstName", "common")}
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupFirstName">
                <i className="fa fa-user" />
              </span>
              <input
                type="text"
                className="form-control"
                id="validationCustomFirstName"
                aria-describedby="inputGroupFirstName"
                placeholder="John"
                name="firstName"
                onChange={handleInputChange}
                value={formData.firstName}
              />
              <div className="invalid-feedback">...</div>
            </div>
          </div>
          <div className="col-6 ps-2">
            <label htmlFor="validationCustomLastName" className="form-label">
              {t("LastName", "common")}
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupLastName">
                <i className="fa fa-user" />
              </span>
              <input
                type="text"
                className="form-control"
                id="validationCustomLastName"
                aria-describedby="inputGroupLastName"
                placeholder="Smith"
                name="lastName"
                onChange={handleInputChange}
                value={formData.lastName}
              />
              <div className="invalid-feedback">...</div>
            </div>
          </div>
        </div>

        <label htmlFor="validationCustomSurname" className="form-label my-2">
          {t("Mobile number", "common")}
        </label>
        <div className="input-group has-validation">
          <span className="input-group-text" id="inputGroupSurname">
            <i className="fa fa-phone" />
          </span>
          <input
            type="text"
            className="form-control"
            id="validationCustomSurname"
            aria-describedby="inputGroupSurname"
            placeholder="0612345678"
            name="phoneNumber"
            onChange={handleInputChange}
            value={formData.phoneNumber}
          />
          <div className="invalid-feedback">...</div>
        </div>
        <label htmlFor="validationCustomEmail" className="form-label my-2">
          {t("Email", "common")}
        </label>
        <div className="input-group has-validation">
          <span className="input-group-text" id="inputGroupEmail">
            <i className="fa fa-envelope" />
          </span>
          <input
            type="text"
            className="form-control"
            id="validationCustomEmail"
            aria-describedby="inputGroupEmail"
            placeholder="johnsmith@example.com"
            name="emailAddress"
            onChange={handleInputChange}
            value={formData.emailAddress}
          />
          <div className="invalid-feedback">...</div>
        </div>
        <label htmlFor="validationCustomPassword" className="form-label my-2">
          {t("Password", "common")}
        </label>
        <div className="input-group has-validation">
          <span className="input-group-text" id="inputGroupPassword">
            <i className="fa fa-lock" />
          </span>
          <input
            type="password"
            className="form-control"
            id="validationCustomPassword"
            aria-describedby="inputGroupPassword"
            placeholder="************"
            name="password"
            onChange={handleInputChange}
            value={formData.password}
          />
          <div className="invalid-feedback" />
        </div>
        <div className="col-12 my-2 form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="termsAgreedInput"
            name="termsAgreed"
            onChange={handleInputChange}
            checked={formData.termsAgreed}
          />
          <label className="form-check-label" htmlFor="termsAgreedInput">
            {t("Accept the terms & condition", "common")}
          </label>
        </div>
      </div>
      {Array.isArray(registerError) && registerError?.length ? (
        registerError.map((e, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="text-danger fs-6">
            {e}
          </div>
        ))
      ) : (
        <div className="text-danger fs-6">{registerError}</div>
      )}
      <div className="login-btn-block col-12 col-md-10 offset-md-1">
        <button
          className="login-btn btn btn-primary col-12 col-lg-8"
          type="submit"
        >
          REGISTER
        </button>
        <Link
          className="register-btn text-muted text-decoration-none text-nowrap"
          to="/login"
        >
          {t("Or click here to login", "common")}
        </Link>
      </div>
    </form>
  );
};

export default Register;
