import React, { ChangeEvent, useState } from "react";

import { AiOutlineUserAdd } from "react-icons/ai";
import Modal from "react-modal";
import { toast } from "react-toastify";

import { useSendRegisterSmsMutation } from "../../graphql/__generated__/graphql_types";
import "./CreateUserModal.scss";

const CreateUserModal = (props: {
  isOpen: boolean;
  closeModal: () => void;
  phoneNumber: string;
  cardAlias: string | undefined | null;
  reset: () => void;
}) => {
  const { isOpen, closeModal, phoneNumber, cardAlias, reset } = props;
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState(phoneNumber);

  const [sendSMS] = useSendRegisterSmsMutation();

  const sendRegisterSMS = async () => {
    // TODO: Handle flow if no cardAlias is selected. IE if no order is selected, but directly searching

    await sendSMS({
      variables: {
        cardAlias: cardAlias as string,
        to: phoneNumber,
      },
    }).then(
      () => {
        reset();
        closeModal();
        toast.success("De uitnodiging is verzonden via SMS!");
      },
      () => {
        toast.error("Telefoonnummer is niet valide.");
        closeModal();
      }
    );
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;

    setMobilePhoneNumber(value);
  };

  const modalProps = {
    id: "createUserModal",
    isOpen,
    onRequestClose: closeModal,
    className: "col-12 d-flex justify-content-center align-items-center",
    style: { overlay: { background: "rgba(0,0,0, 0.5)", zIndex: 2000 } },
  };
  Modal.setAppElement("#root");
  return (
    <Modal {...modalProps}>
      <div className="rounded-3 col-11 col-md-10 col-lg-8">
        <div className="top-block col-12 d-flex justify-content-center align-items-center flex-column">
          <div className="information-icon-block">
            <div className="information-icon rounded-circle">
              <AiOutlineUserAdd color="white" size={30} />
            </div>
          </div>
          <div className="title text-center d-flex justify-content-center align-items-center">
            <p className="m-0">Koppel kaart aan nieuwe gebruiker</p>
          </div>
        </div>

        <div className="bottom-block text-center">
          <div className="py-5">
            U staat op het punt om een gebruiker uit te nodigen met
            telefoonnummer:
            <div>
              <input
                value={mobilePhoneNumber}
                name="mobilePhone"
                onChange={handleInputChange}
                className="my-3"
              />
            </div>
            De klant krijgt een verificatie sms en bij een correcte afhandeling
            van de sms is de kaart gekoppeld.
          </div>
          <div className="pb-5 col-12 d-flex justify-content-center align-items-center">
            <button
              className="cancel-btn mx-2"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="confirm-btn mx-2"
              type="button"
              onClick={sendRegisterSMS}
            >
              Bevestig
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
