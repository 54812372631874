import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AddManualPaymentResponse = {
  __typename?: 'AddManualPaymentResponse';
  amountOfPoints: Scalars['Int'];
  amountInCents: Scalars['Int'];
  id: Scalars['String'];
};

export type AddPointsInput = {
  amount: Scalars['String'];
  email: Scalars['String'];
};

export type AdyenStatusNotification = {
  __typename?: 'AdyenStatusNotification';
  amountOfLastPayment?: Maybe<Scalars['Int']>;
  countOfEcomPayments?: Maybe<Scalars['Int']>;
  countOfPOSPayments?: Maybe<Scalars['Int']>;
  totalCountOfTransactions?: Maybe<Scalars['Int']>;
  totalAmountOfPoints?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  isCardRecognized?: Maybe<Scalars['Boolean']>;
  isCardConnectedToUser?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  customerKey?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type CardAcquisitionUnion = User | SmsPayload;

export type CardModel = {
  __typename?: 'CardModel';
  cardAlias?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ConnectCardToUserInput = {
  userEmail: Scalars['String'];
  orderId: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  cardAlias?: Maybe<Scalars['String']>;
  termsAgreed: Scalars['Boolean'];
};


export type GetLevelRewards = {
  __typename?: 'GetLevelRewards';
  uuid: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type GetLevelRewardsByEmail = {
  __typename?: 'GetLevelRewardsByEmail';
  uuid: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  imageUrl: Scalars['String'];
  redeemable: Scalars['Boolean'];
};

export type GetUserLevelByEmail = {
  __typename?: 'GetUserLevelByEmail';
  email: Scalars['String'];
  level: Scalars['Float'];
  levelName: Scalars['String'];
  nextLevel: Scalars['Float'];
  piggyLoyaltyTotalCreditsReceived: Scalars['Float'];
};

export type LevelRewards = {
  __typename?: 'LevelRewards';
  reward_type: Scalars['String'];
  id: Scalars['Float'];
  description: Scalars['String'];
  uuid: Scalars['String'];
  title: Scalars['String'];
  required_credits: Scalars['Float'];
  active: Scalars['Boolean'];
  displayName: Scalars['String'];
  rewardLevel: Scalars['String'];
  rewardImage: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  sendRegisterSMS: SmsPayload;
  connectCardToUser: CardModel;
  addManualPoints: AddManualPaymentResponse;
  createUser: User;
  deleteUser: User;
  updateUserAccountData: UserAccountDataUpdated;
  cardAcquisition: CardAcquisitionUnion;
  redeemPointsManually: RedeemedPoints;
  redeemLevelReward: RedeemRewardResponse;
};


export type MutationSendRegisterSmsArgs = {
  sendRegisterSMSInput: SendRegisterSmsInput;
};


export type MutationConnectCardToUserArgs = {
  connectCardToUserInput: ConnectCardToUserInput;
};


export type MutationAddManualPointsArgs = {
  userEmail: Scalars['String'];
  amountInCents: Scalars['String'];
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationDeleteUserArgs = {
  userEmail: Scalars['String'];
};


export type MutationUpdateUserAccountDataArgs = {
  updateUserAccountInput: UpdateUserAccountInput;
  userEmailFromClient: Scalars['String'];
};


export type MutationRedeemPointsManuallyArgs = {
  addPointsInput: AddPointsInput;
};


export type MutationRedeemLevelRewardArgs = {
  redeemRewardInput: RedeemRewardInput;
};

export type OverviewRewards = {
  __typename?: 'OverviewRewards';
  id: Scalars['String'];
  uuid: Scalars['String'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  description: Scalars['String'];
  imageUrl: Scalars['String'];
  enabled: Scalars['Boolean'];
  redeemed: Scalars['Boolean'];
  level: Scalars['Float'];
  levelName: Scalars['String'];
  levelDescription: Scalars['String'];
};

export type Points = {
  __typename?: 'Points';
  amount: Scalars['Int'];
  id: Scalars['String'];
  validFrom: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  findUniqueCard?: Maybe<CardModel>;
  searchFilterUsers: Array<User>;
  verifyUserToken: User;
  getDataFromSearchedUser: AdyenStatusNotification;
  getUserOverviewData: UserWithPoints;
  getUserViaCardAcquisition: User;
  getLevelRewards: Array<GetLevelRewards>;
  getLevelRewardsByEmail: Array<GetLevelRewardsByEmail>;
  getOverviewLevelRewardsByEmail: Array<OverviewRewards>;
  getUserLevelByEmail: GetUserLevelByEmail;
};


export type QueryFindUniqueCardArgs = {
  card: Scalars['String'];
};


export type QuerySearchFilterUsersArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryVerifyUserTokenArgs = {
  email: Scalars['String'];
};


export type QueryGetDataFromSearchedUserArgs = {
  email: Scalars['String'];
};


export type QueryGetUserOverviewDataArgs = {
  email: Scalars['String'];
};


export type QueryGetLevelRewardsArgs = {
  level: Scalars['Float'];
};


export type QueryGetLevelRewardsByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetOverviewLevelRewardsByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUserLevelByEmailArgs = {
  email: Scalars['String'];
};

export type RedeemRewardInput = {
  rewardUuid: Scalars['String'];
  email: Scalars['String'];
};

export type RedeemRewardResponse = {
  __typename?: 'RedeemRewardResponse';
  status: Scalars['Boolean'];
  message: Scalars['String'];
};

export type RedeemedPoints = {
  __typename?: 'RedeemedPoints';
  id: Scalars['String'];
  amount: Scalars['Float'];
};

export enum Role {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type SmsPayload = {
  __typename?: 'SMSPayload';
  smsId: Scalars['String'];
};

export type SendRegisterSmsInput = {
  to: Scalars['String'];
  cardAlias?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  adyenIncomingPayment: AdyenStatusNotification;
};

export type UpdateUserAccountInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  Role?: Maybe<Role>;
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isTokenValid?: Maybe<Scalars['Boolean']>;
};

export type UserAccountDataUpdated = {
  __typename?: 'UserAccountDataUpdated';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  isEmailChanged?: Maybe<Scalars['Boolean']>;
};

export type UserLevelType = {
  __typename?: 'UserLevelType';
  email: Scalars['String'];
  level: Scalars['Float'];
  levelName: Scalars['String'];
  nextLevel: Scalars['Float'];
  piggyLoyaltyTotalCreditsReceived: Scalars['Float'];
};

export type UserWithPoints = {
  __typename?: 'UserWithPoints';
  totalAmountOfPoints: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  Points: Array<Points>;
  phoneNumber: Scalars['String'];
  userLevel: UserLevelType;
  levelRewards: Array<LevelRewards>;
};

export type FindUniqueCardQueryVariables = Exact<{
  card: Scalars['String'];
}>;


export type FindUniqueCardQuery = (
  { __typename?: 'Query' }
  & { findUniqueCard?: Maybe<(
    { __typename?: 'CardModel' }
    & Pick<CardModel, 'cardAlias' | 'phoneNumber'>
  )> }
);

export type ConnectCardToUserMutationVariables = Exact<{
  userEmail: Scalars['String'];
  orderId: Scalars['String'];
}>;


export type ConnectCardToUserMutation = (
  { __typename?: 'Mutation' }
  & { connectCardToUser: (
    { __typename?: 'CardModel' }
    & Pick<CardModel, 'cardAlias'>
  ) }
);

export type AdyenStatusNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AdyenStatusNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { adyenIncomingPayment: (
    { __typename?: 'AdyenStatusNotification' }
    & Pick<AdyenStatusNotification, 'status' | 'isCardRecognized' | 'isCardConnectedToUser' | 'countOfEcomPayments' | 'countOfPOSPayments' | 'totalAmountOfPoints' | 'orderId' | 'amountOfLastPayment' | 'customerKey' | 'totalCountOfTransactions' | 'date'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'firstName' | 'lastName' | 'phoneNumber'>
    )> }
  ) }
);

export type AddManualPaymentMutationVariables = Exact<{
  amountInCents: Scalars['String'];
  userEmail: Scalars['String'];
}>;


export type AddManualPaymentMutation = (
  { __typename?: 'Mutation' }
  & { addManualPoints: (
    { __typename?: 'AddManualPaymentResponse' }
    & Pick<AddManualPaymentResponse, 'id' | 'amountInCents' | 'amountOfPoints'>
  ) }
);

export type GetUserLevelByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUserLevelByEmailQuery = (
  { __typename?: 'Query' }
  & { getUserLevelByEmail: (
    { __typename?: 'GetUserLevelByEmail' }
    & Pick<GetUserLevelByEmail, 'email' | 'level' | 'levelName' | 'nextLevel' | 'piggyLoyaltyTotalCreditsReceived'>
  ) }
);

export type RedeemPointsManuallyMutationVariables = Exact<{
  amount: Scalars['String'];
  email: Scalars['String'];
}>;


export type RedeemPointsManuallyMutation = (
  { __typename?: 'Mutation' }
  & { redeemPointsManually: (
    { __typename?: 'RedeemedPoints' }
    & Pick<RedeemedPoints, 'id' | 'amount'>
  ) }
);

export type GetLevelRewardsQueryVariables = Exact<{
  level: Scalars['Float'];
}>;


export type GetLevelRewardsQuery = (
  { __typename?: 'Query' }
  & { getLevelRewards: Array<(
    { __typename?: 'GetLevelRewards' }
    & Pick<GetLevelRewards, 'uuid' | 'name' | 'description' | 'imageUrl'>
  )> }
);

export type GetLevelRewardsByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetLevelRewardsByEmailQuery = (
  { __typename?: 'Query' }
  & { getLevelRewardsByEmail: Array<(
    { __typename?: 'GetLevelRewardsByEmail' }
    & Pick<GetLevelRewardsByEmail, 'uuid' | 'name' | 'description' | 'imageUrl' | 'redeemable'>
  )> }
);

export type GetOverviewLevelRewardsByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetOverviewLevelRewardsByEmailQuery = (
  { __typename?: 'Query' }
  & { getOverviewLevelRewardsByEmail: Array<(
    { __typename?: 'OverviewRewards' }
    & Pick<OverviewRewards, 'id' | 'uuid' | 'name' | 'displayName' | 'description' | 'imageUrl' | 'level' | 'levelName' | 'levelDescription' | 'redeemed'>
  )> }
);

export type RedeemLevelRewardMutationVariables = Exact<{
  rewardUuid: Scalars['String'];
  email: Scalars['String'];
}>;


export type RedeemLevelRewardMutation = (
  { __typename?: 'Mutation' }
  & { redeemLevelReward: (
    { __typename?: 'RedeemRewardResponse' }
    & Pick<RedeemRewardResponse, 'status' | 'message'>
  ) }
);

export type SearchUsersQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { searchFilterUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'phoneNumber' | 'firstName' | 'lastName'>
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  termsAgreed: Scalars['Boolean'];
  customerKey?: Maybe<Scalars['String']>;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName'>
  ) }
);

export type CheckTokenQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckTokenQuery = (
  { __typename?: 'Query' }
  & { verifyUserToken: (
    { __typename?: 'User' }
    & Pick<User, 'isTokenValid' | 'Role'>
  ) }
);

export type SendRegisterSmsMutationVariables = Exact<{
  to: Scalars['String'];
  cardAlias?: Maybe<Scalars['String']>;
}>;


export type SendRegisterSmsMutation = (
  { __typename?: 'Mutation' }
  & { sendRegisterSMS: (
    { __typename?: 'SMSPayload' }
    & Pick<SmsPayload, 'smsId'>
  ) }
);

export type GetDataFromSearchedUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetDataFromSearchedUserQuery = (
  { __typename?: 'Query' }
  & { getDataFromSearchedUser: (
    { __typename?: 'AdyenStatusNotification' }
    & Pick<AdyenStatusNotification, 'countOfEcomPayments' | 'countOfPOSPayments' | 'totalCountOfTransactions' | 'totalAmountOfPoints'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
    )> }
  ) }
);

export type GetUserOverviewDataQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUserOverviewDataQuery = (
  { __typename?: 'Query' }
  & { getUserOverviewData: (
    { __typename?: 'UserWithPoints' }
    & Pick<UserWithPoints, 'firstName' | 'lastName' | 'phoneNumber' | 'totalAmountOfPoints'>
    & { Points: Array<(
      { __typename?: 'Points' }
      & Pick<Points, 'id' | 'amount' | 'validFrom' | 'createdAt'>
    )>, userLevel: (
      { __typename?: 'UserLevelType' }
      & Pick<UserLevelType, 'email' | 'level' | 'levelName' | 'nextLevel' | 'piggyLoyaltyTotalCreditsReceived'>
    ), levelRewards: Array<(
      { __typename?: 'LevelRewards' }
      & Pick<LevelRewards, 'id' | 'uuid' | 'reward_type' | 'description' | 'title' | 'required_credits' | 'active' | 'displayName' | 'rewardLevel' | 'rewardImage'>
    )> }
  ) }
);

export type UpdateUserAccountMutationVariables = Exact<{
  updateUserAccountInput: UpdateUserAccountInput;
  userEmailFromClient: Scalars['String'];
}>;


export type UpdateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAccountData: (
    { __typename?: 'UserAccountDataUpdated' }
    & Pick<UserAccountDataUpdated, 'email' | 'firstName' | 'isEmailChanged'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  userEmail: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type CardAcquisitionMutationVariables = Exact<{ [key: string]: never; }>;


export type CardAcquisitionMutation = (
  { __typename?: 'Mutation' }
  & { cardAcquisition: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName'>
  ) | (
    { __typename?: 'SMSPayload' }
    & Pick<SmsPayload, 'smsId'>
  ) }
);

export type GetUserViaCardAcquisitionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserViaCardAcquisitionQuery = (
  { __typename?: 'Query' }
  & { getUserViaCardAcquisition: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName'>
  ) }
);


export const FindUniqueCardDocument = gql`
    query findUniqueCard($card: String!) {
  findUniqueCard(card: $card) {
    cardAlias
    phoneNumber
  }
}
    `;

/**
 * __useFindUniqueCardQuery__
 *
 * To run a query within a React component, call `useFindUniqueCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueCardQuery({
 *   variables: {
 *      card: // value for 'card'
 *   },
 * });
 */
export function useFindUniqueCardQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueCardQuery, FindUniqueCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueCardQuery, FindUniqueCardQueryVariables>(FindUniqueCardDocument, options);
      }
export function useFindUniqueCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueCardQuery, FindUniqueCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueCardQuery, FindUniqueCardQueryVariables>(FindUniqueCardDocument, options);
        }
export type FindUniqueCardQueryHookResult = ReturnType<typeof useFindUniqueCardQuery>;
export type FindUniqueCardLazyQueryHookResult = ReturnType<typeof useFindUniqueCardLazyQuery>;
export type FindUniqueCardQueryResult = Apollo.QueryResult<FindUniqueCardQuery, FindUniqueCardQueryVariables>;
export const ConnectCardToUserDocument = gql`
    mutation connectCardToUser($userEmail: String!, $orderId: String!) {
  connectCardToUser(
    connectCardToUserInput: {userEmail: $userEmail, orderId: $orderId}
  ) {
    cardAlias
  }
}
    `;
export type ConnectCardToUserMutationFn = Apollo.MutationFunction<ConnectCardToUserMutation, ConnectCardToUserMutationVariables>;

/**
 * __useConnectCardToUserMutation__
 *
 * To run a mutation, you first call `useConnectCardToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectCardToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectCardToUserMutation, { data, loading, error }] = useConnectCardToUserMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useConnectCardToUserMutation(baseOptions?: Apollo.MutationHookOptions<ConnectCardToUserMutation, ConnectCardToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectCardToUserMutation, ConnectCardToUserMutationVariables>(ConnectCardToUserDocument, options);
      }
export type ConnectCardToUserMutationHookResult = ReturnType<typeof useConnectCardToUserMutation>;
export type ConnectCardToUserMutationResult = Apollo.MutationResult<ConnectCardToUserMutation>;
export type ConnectCardToUserMutationOptions = Apollo.BaseMutationOptions<ConnectCardToUserMutation, ConnectCardToUserMutationVariables>;
export const AdyenStatusNotificationDocument = gql`
    subscription AdyenStatusNotification {
  adyenIncomingPayment {
    status
    isCardRecognized
    isCardConnectedToUser
    countOfEcomPayments
    countOfPOSPayments
    totalAmountOfPoints
    orderId
    amountOfLastPayment
    customerKey
    totalCountOfTransactions
    user {
      email
      firstName
      lastName
      phoneNumber
    }
    date
  }
}
    `;

/**
 * __useAdyenStatusNotificationSubscription__
 *
 * To run a query within a React component, call `useAdyenStatusNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAdyenStatusNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdyenStatusNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAdyenStatusNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AdyenStatusNotificationSubscription, AdyenStatusNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AdyenStatusNotificationSubscription, AdyenStatusNotificationSubscriptionVariables>(AdyenStatusNotificationDocument, options);
      }
export type AdyenStatusNotificationSubscriptionHookResult = ReturnType<typeof useAdyenStatusNotificationSubscription>;
export type AdyenStatusNotificationSubscriptionResult = Apollo.SubscriptionResult<AdyenStatusNotificationSubscription>;
export const AddManualPaymentDocument = gql`
    mutation AddManualPayment($amountInCents: String!, $userEmail: String!) {
  addManualPoints(amountInCents: $amountInCents, userEmail: $userEmail) {
    id
    amountInCents
    amountOfPoints
  }
}
    `;
export type AddManualPaymentMutationFn = Apollo.MutationFunction<AddManualPaymentMutation, AddManualPaymentMutationVariables>;

/**
 * __useAddManualPaymentMutation__
 *
 * To run a mutation, you first call `useAddManualPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManualPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManualPaymentMutation, { data, loading, error }] = useAddManualPaymentMutation({
 *   variables: {
 *      amountInCents: // value for 'amountInCents'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useAddManualPaymentMutation(baseOptions?: Apollo.MutationHookOptions<AddManualPaymentMutation, AddManualPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddManualPaymentMutation, AddManualPaymentMutationVariables>(AddManualPaymentDocument, options);
      }
export type AddManualPaymentMutationHookResult = ReturnType<typeof useAddManualPaymentMutation>;
export type AddManualPaymentMutationResult = Apollo.MutationResult<AddManualPaymentMutation>;
export type AddManualPaymentMutationOptions = Apollo.BaseMutationOptions<AddManualPaymentMutation, AddManualPaymentMutationVariables>;
export const GetUserLevelByEmailDocument = gql`
    query getUserLevelByEmail($email: String!) {
  getUserLevelByEmail(email: $email) {
    email
    level
    levelName
    nextLevel
    piggyLoyaltyTotalCreditsReceived
  }
}
    `;

/**
 * __useGetUserLevelByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserLevelByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLevelByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLevelByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserLevelByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUserLevelByEmailQuery, GetUserLevelByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLevelByEmailQuery, GetUserLevelByEmailQueryVariables>(GetUserLevelByEmailDocument, options);
      }
export function useGetUserLevelByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLevelByEmailQuery, GetUserLevelByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLevelByEmailQuery, GetUserLevelByEmailQueryVariables>(GetUserLevelByEmailDocument, options);
        }
export type GetUserLevelByEmailQueryHookResult = ReturnType<typeof useGetUserLevelByEmailQuery>;
export type GetUserLevelByEmailLazyQueryHookResult = ReturnType<typeof useGetUserLevelByEmailLazyQuery>;
export type GetUserLevelByEmailQueryResult = Apollo.QueryResult<GetUserLevelByEmailQuery, GetUserLevelByEmailQueryVariables>;
export const RedeemPointsManuallyDocument = gql`
    mutation redeemPointsManually($amount: String!, $email: String!) {
  redeemPointsManually(addPointsInput: {amount: $amount, email: $email}) {
    id
    amount
  }
}
    `;
export type RedeemPointsManuallyMutationFn = Apollo.MutationFunction<RedeemPointsManuallyMutation, RedeemPointsManuallyMutationVariables>;

/**
 * __useRedeemPointsManuallyMutation__
 *
 * To run a mutation, you first call `useRedeemPointsManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPointsManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPointsManuallyMutation, { data, loading, error }] = useRedeemPointsManuallyMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRedeemPointsManuallyMutation(baseOptions?: Apollo.MutationHookOptions<RedeemPointsManuallyMutation, RedeemPointsManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemPointsManuallyMutation, RedeemPointsManuallyMutationVariables>(RedeemPointsManuallyDocument, options);
      }
export type RedeemPointsManuallyMutationHookResult = ReturnType<typeof useRedeemPointsManuallyMutation>;
export type RedeemPointsManuallyMutationResult = Apollo.MutationResult<RedeemPointsManuallyMutation>;
export type RedeemPointsManuallyMutationOptions = Apollo.BaseMutationOptions<RedeemPointsManuallyMutation, RedeemPointsManuallyMutationVariables>;
export const GetLevelRewardsDocument = gql`
    query getLevelRewards($level: Float!) {
  getLevelRewards(level: $level) {
    uuid
    name
    description
    imageUrl
  }
}
    `;

/**
 * __useGetLevelRewardsQuery__
 *
 * To run a query within a React component, call `useGetLevelRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLevelRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLevelRewardsQuery({
 *   variables: {
 *      level: // value for 'level'
 *   },
 * });
 */
export function useGetLevelRewardsQuery(baseOptions: Apollo.QueryHookOptions<GetLevelRewardsQuery, GetLevelRewardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLevelRewardsQuery, GetLevelRewardsQueryVariables>(GetLevelRewardsDocument, options);
      }
export function useGetLevelRewardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLevelRewardsQuery, GetLevelRewardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLevelRewardsQuery, GetLevelRewardsQueryVariables>(GetLevelRewardsDocument, options);
        }
export type GetLevelRewardsQueryHookResult = ReturnType<typeof useGetLevelRewardsQuery>;
export type GetLevelRewardsLazyQueryHookResult = ReturnType<typeof useGetLevelRewardsLazyQuery>;
export type GetLevelRewardsQueryResult = Apollo.QueryResult<GetLevelRewardsQuery, GetLevelRewardsQueryVariables>;
export const GetLevelRewardsByEmailDocument = gql`
    query getLevelRewardsByEmail($email: String!) {
  getLevelRewardsByEmail(email: $email) {
    uuid
    name
    description
    imageUrl
    redeemable
  }
}
    `;

/**
 * __useGetLevelRewardsByEmailQuery__
 *
 * To run a query within a React component, call `useGetLevelRewardsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLevelRewardsByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLevelRewardsByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetLevelRewardsByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetLevelRewardsByEmailQuery, GetLevelRewardsByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLevelRewardsByEmailQuery, GetLevelRewardsByEmailQueryVariables>(GetLevelRewardsByEmailDocument, options);
      }
export function useGetLevelRewardsByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLevelRewardsByEmailQuery, GetLevelRewardsByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLevelRewardsByEmailQuery, GetLevelRewardsByEmailQueryVariables>(GetLevelRewardsByEmailDocument, options);
        }
export type GetLevelRewardsByEmailQueryHookResult = ReturnType<typeof useGetLevelRewardsByEmailQuery>;
export type GetLevelRewardsByEmailLazyQueryHookResult = ReturnType<typeof useGetLevelRewardsByEmailLazyQuery>;
export type GetLevelRewardsByEmailQueryResult = Apollo.QueryResult<GetLevelRewardsByEmailQuery, GetLevelRewardsByEmailQueryVariables>;
export const GetOverviewLevelRewardsByEmailDocument = gql`
    query getOverviewLevelRewardsByEmail($email: String!) {
  getOverviewLevelRewardsByEmail(email: $email) {
    id
    uuid
    name
    displayName
    description
    imageUrl
    level
    levelName
    levelDescription
    redeemed
  }
}
    `;

/**
 * __useGetOverviewLevelRewardsByEmailQuery__
 *
 * To run a query within a React component, call `useGetOverviewLevelRewardsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverviewLevelRewardsByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverviewLevelRewardsByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetOverviewLevelRewardsByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetOverviewLevelRewardsByEmailQuery, GetOverviewLevelRewardsByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverviewLevelRewardsByEmailQuery, GetOverviewLevelRewardsByEmailQueryVariables>(GetOverviewLevelRewardsByEmailDocument, options);
      }
export function useGetOverviewLevelRewardsByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverviewLevelRewardsByEmailQuery, GetOverviewLevelRewardsByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverviewLevelRewardsByEmailQuery, GetOverviewLevelRewardsByEmailQueryVariables>(GetOverviewLevelRewardsByEmailDocument, options);
        }
export type GetOverviewLevelRewardsByEmailQueryHookResult = ReturnType<typeof useGetOverviewLevelRewardsByEmailQuery>;
export type GetOverviewLevelRewardsByEmailLazyQueryHookResult = ReturnType<typeof useGetOverviewLevelRewardsByEmailLazyQuery>;
export type GetOverviewLevelRewardsByEmailQueryResult = Apollo.QueryResult<GetOverviewLevelRewardsByEmailQuery, GetOverviewLevelRewardsByEmailQueryVariables>;
export const RedeemLevelRewardDocument = gql`
    mutation redeemLevelReward($rewardUuid: String!, $email: String!) {
  redeemLevelReward(redeemRewardInput: {rewardUuid: $rewardUuid, email: $email}) {
    status
    message
  }
}
    `;
export type RedeemLevelRewardMutationFn = Apollo.MutationFunction<RedeemLevelRewardMutation, RedeemLevelRewardMutationVariables>;

/**
 * __useRedeemLevelRewardMutation__
 *
 * To run a mutation, you first call `useRedeemLevelRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemLevelRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemLevelRewardMutation, { data, loading, error }] = useRedeemLevelRewardMutation({
 *   variables: {
 *      rewardUuid: // value for 'rewardUuid'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRedeemLevelRewardMutation(baseOptions?: Apollo.MutationHookOptions<RedeemLevelRewardMutation, RedeemLevelRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemLevelRewardMutation, RedeemLevelRewardMutationVariables>(RedeemLevelRewardDocument, options);
      }
export type RedeemLevelRewardMutationHookResult = ReturnType<typeof useRedeemLevelRewardMutation>;
export type RedeemLevelRewardMutationResult = Apollo.MutationResult<RedeemLevelRewardMutation>;
export type RedeemLevelRewardMutationOptions = Apollo.BaseMutationOptions<RedeemLevelRewardMutation, RedeemLevelRewardMutationVariables>;
export const SearchUsersDocument = gql`
    query searchUsers($filter: String) {
  searchFilterUsers(filter: $filter) {
    email
    phoneNumber
    firstName
    lastName
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($emailAddress: String!, $firstName: String!, $lastName: String!, $password: String!, $phoneNumber: String!, $termsAgreed: Boolean!, $customerKey: String) {
  createUser(
    createUserInput: {email: $emailAddress, firstName: $firstName, lastName: $lastName, password: $password, phoneNumber: $phoneNumber, termsAgreed: $termsAgreed, cardAlias: $customerKey}
  ) {
    firstName
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *      termsAgreed: // value for 'termsAgreed'
 *      customerKey: // value for 'customerKey'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const CheckTokenDocument = gql`
    query checkToken($email: String!) {
  verifyUserToken(email: $email) {
    isTokenValid
    Role
  }
}
    `;

/**
 * __useCheckTokenQuery__
 *
 * To run a query within a React component, call `useCheckTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckTokenQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckTokenQuery(baseOptions: Apollo.QueryHookOptions<CheckTokenQuery, CheckTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckTokenQuery, CheckTokenQueryVariables>(CheckTokenDocument, options);
      }
export function useCheckTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckTokenQuery, CheckTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckTokenQuery, CheckTokenQueryVariables>(CheckTokenDocument, options);
        }
export type CheckTokenQueryHookResult = ReturnType<typeof useCheckTokenQuery>;
export type CheckTokenLazyQueryHookResult = ReturnType<typeof useCheckTokenLazyQuery>;
export type CheckTokenQueryResult = Apollo.QueryResult<CheckTokenQuery, CheckTokenQueryVariables>;
export const SendRegisterSmsDocument = gql`
    mutation sendRegisterSMS($to: String!, $cardAlias: String) {
  sendRegisterSMS(sendRegisterSMSInput: {to: $to, cardAlias: $cardAlias}) {
    smsId
  }
}
    `;
export type SendRegisterSmsMutationFn = Apollo.MutationFunction<SendRegisterSmsMutation, SendRegisterSmsMutationVariables>;

/**
 * __useSendRegisterSmsMutation__
 *
 * To run a mutation, you first call `useSendRegisterSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRegisterSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRegisterSmsMutation, { data, loading, error }] = useSendRegisterSmsMutation({
 *   variables: {
 *      to: // value for 'to'
 *      cardAlias: // value for 'cardAlias'
 *   },
 * });
 */
export function useSendRegisterSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendRegisterSmsMutation, SendRegisterSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendRegisterSmsMutation, SendRegisterSmsMutationVariables>(SendRegisterSmsDocument, options);
      }
export type SendRegisterSmsMutationHookResult = ReturnType<typeof useSendRegisterSmsMutation>;
export type SendRegisterSmsMutationResult = Apollo.MutationResult<SendRegisterSmsMutation>;
export type SendRegisterSmsMutationOptions = Apollo.BaseMutationOptions<SendRegisterSmsMutation, SendRegisterSmsMutationVariables>;
export const GetDataFromSearchedUserDocument = gql`
    query getDataFromSearchedUser($email: String!) {
  getDataFromSearchedUser(email: $email) {
    countOfEcomPayments
    countOfPOSPayments
    user {
      firstName
      lastName
      email
      phoneNumber
    }
    totalCountOfTransactions
    totalAmountOfPoints
  }
}
    `;

/**
 * __useGetDataFromSearchedUserQuery__
 *
 * To run a query within a React component, call `useGetDataFromSearchedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataFromSearchedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataFromSearchedUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetDataFromSearchedUserQuery(baseOptions: Apollo.QueryHookOptions<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>(GetDataFromSearchedUserDocument, options);
      }
export function useGetDataFromSearchedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>(GetDataFromSearchedUserDocument, options);
        }
export type GetDataFromSearchedUserQueryHookResult = ReturnType<typeof useGetDataFromSearchedUserQuery>;
export type GetDataFromSearchedUserLazyQueryHookResult = ReturnType<typeof useGetDataFromSearchedUserLazyQuery>;
export type GetDataFromSearchedUserQueryResult = Apollo.QueryResult<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>;
export const GetUserOverviewDataDocument = gql`
    query getUserOverviewData($email: String!) {
  getUserOverviewData(email: $email) {
    firstName
    lastName
    phoneNumber
    totalAmountOfPoints
    Points {
      id
      amount
      validFrom
      createdAt
    }
    userLevel {
      email
      level
      levelName
      nextLevel
      piggyLoyaltyTotalCreditsReceived
    }
    levelRewards {
      id
      uuid
      reward_type
      description
      title
      required_credits
      active
      displayName
      rewardLevel
      rewardImage
    }
  }
}
    `;

/**
 * __useGetUserOverviewDataQuery__
 *
 * To run a query within a React component, call `useGetUserOverviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOverviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOverviewDataQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserOverviewDataQuery(baseOptions: Apollo.QueryHookOptions<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>(GetUserOverviewDataDocument, options);
      }
export function useGetUserOverviewDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>(GetUserOverviewDataDocument, options);
        }
export type GetUserOverviewDataQueryHookResult = ReturnType<typeof useGetUserOverviewDataQuery>;
export type GetUserOverviewDataLazyQueryHookResult = ReturnType<typeof useGetUserOverviewDataLazyQuery>;
export type GetUserOverviewDataQueryResult = Apollo.QueryResult<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>;
export const UpdateUserAccountDocument = gql`
    mutation updateUserAccount($updateUserAccountInput: UpdateUserAccountInput!, $userEmailFromClient: String!) {
  updateUserAccountData(
    updateUserAccountInput: $updateUserAccountInput
    userEmailFromClient: $userEmailFromClient
  ) {
    email
    firstName
    isEmailChanged
  }
}
    `;
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      updateUserAccountInput: // value for 'updateUserAccountInput'
 *      userEmailFromClient: // value for 'userEmailFromClient'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(UpdateUserAccountDocument, options);
      }
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = Apollo.MutationResult<UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($userEmail: String!) {
  deleteUser(userEmail: $userEmail) {
    email
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const CardAcquisitionDocument = gql`
    mutation cardAcquisition {
  cardAcquisition {
    ... on User {
      email
      firstName
      lastName
    }
    ... on SMSPayload {
      smsId
    }
  }
}
    `;
export type CardAcquisitionMutationFn = Apollo.MutationFunction<CardAcquisitionMutation, CardAcquisitionMutationVariables>;

/**
 * __useCardAcquisitionMutation__
 *
 * To run a mutation, you first call `useCardAcquisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCardAcquisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cardAcquisitionMutation, { data, loading, error }] = useCardAcquisitionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCardAcquisitionMutation(baseOptions?: Apollo.MutationHookOptions<CardAcquisitionMutation, CardAcquisitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CardAcquisitionMutation, CardAcquisitionMutationVariables>(CardAcquisitionDocument, options);
      }
export type CardAcquisitionMutationHookResult = ReturnType<typeof useCardAcquisitionMutation>;
export type CardAcquisitionMutationResult = Apollo.MutationResult<CardAcquisitionMutation>;
export type CardAcquisitionMutationOptions = Apollo.BaseMutationOptions<CardAcquisitionMutation, CardAcquisitionMutationVariables>;
export const GetUserViaCardAcquisitionDocument = gql`
    query getUserViaCardAcquisition {
  getUserViaCardAcquisition {
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useGetUserViaCardAcquisitionQuery__
 *
 * To run a query within a React component, call `useGetUserViaCardAcquisitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserViaCardAcquisitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserViaCardAcquisitionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserViaCardAcquisitionQuery(baseOptions?: Apollo.QueryHookOptions<GetUserViaCardAcquisitionQuery, GetUserViaCardAcquisitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserViaCardAcquisitionQuery, GetUserViaCardAcquisitionQueryVariables>(GetUserViaCardAcquisitionDocument, options);
      }
export function useGetUserViaCardAcquisitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserViaCardAcquisitionQuery, GetUserViaCardAcquisitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserViaCardAcquisitionQuery, GetUserViaCardAcquisitionQueryVariables>(GetUserViaCardAcquisitionDocument, options);
        }
export type GetUserViaCardAcquisitionQueryHookResult = ReturnType<typeof useGetUserViaCardAcquisitionQuery>;
export type GetUserViaCardAcquisitionLazyQueryHookResult = ReturnType<typeof useGetUserViaCardAcquisitionLazyQuery>;
export type GetUserViaCardAcquisitionQueryResult = Apollo.QueryResult<GetUserViaCardAcquisitionQuery, GetUserViaCardAcquisitionQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CardAcquisitionUnion": [
      "User",
      "SMSPayload"
    ]
  }
};
      export default result;
    