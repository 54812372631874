import React from "react";
import "./UnregisteredResponse.scss";

const UnregisteredResponse = (props: {
  totalCountOfPayments: number | null | undefined;
  orderIdBlock: JSX.Element | null;
}) => {
  const { totalCountOfPayments, orderIdBlock } = props;
  return (
    <div id="unregisteredResponse-block">
      <div className="cardBox-head__alert pt-1 px-3 text-center pb-2">
        <h5 className="text-white pb-2">Betaling herkend</h5>{" "}
        <h5 className="text-main-text pt-1">
          {totalCountOfPayments}x payments before <br /> Not connected to member
        </h5>
      </div>

      {orderIdBlock}
    </div>
  );
};

export default UnregisteredResponse;
