import React from "react";

// css to style

const Redeemed = () => (
  <p
    style={{
      position: "absolute",
      transform: "rotateZ(-45deg)",
      padding: "5px",
      borderRadius: "10px",
      fontSize: "25px",
      background: "#f4a089",
      color: "#4c5340",
      border: "#4c5340 2px solid",
      fontWeight: "700",
      textDecoration: "none solid rgb(68, 68, 68)",
      textTransform: "uppercase",
    }}
    className="redeemed"
  >
    Redeemed
  </p>
);

export default Redeemed;
